import React, { createContext, useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import detectEthereumProvider from "@metamask/detect-provider"
import * as ethers from "ethers"
import Cookies from "js-cookie"
import { useMutation, useQuery } from "@apollo/client"
import { GENERATE_AUTH_CHALLENGE, SIGN_IN } from "@graphql/Auth/login.mutation"
import { IWalletContext } from "./interfaces/walletContext.interface"
import { ISettings, IWalletProps } from "./interfaces/walletProps.interface"
import { SETTINGS } from "@graphql/Settings/settings.query"
import { LoadingBg } from "@Containers/Main/style"
// import axios from 'axios'

export const WalletContext = createContext<IWalletContext>({
  isMetamaskEnabled: false,
  signed: false,
  inMaintenance: false,
  settings: undefined,
  login: () => { },
  logout: () => { },
})

interface IProviderProps {
  children: React.ReactNode
}


export const Provider = ({ children }: IProviderProps) => {
  const [wallet, setWallet] = useState<IWalletProps>({})
  const {
    signed,
    account,
    ethereum,
    isMetamaskEnabled,
    initialized,
    provider,
    inMaintenance,
    contract,
    contractBusd,
  } = wallet

  const { data: dataSettings } = useQuery(SETTINGS)

  const [generateChallenge] = useMutation(GENERATE_AUTH_CHALLENGE)
  const [signIn] = useMutation(SIGN_IN)

  useEffect(() => {
    ; (async () => {
      try {
        const ethereum: any = await detectEthereumProvider()
        if (!ethereum) {
          throw "NO_METAMASK"
        }

        await new Promise((res) => setTimeout(res, 2000))
        await setWallet({
          signed: false,
          ethereum,
          isMetamaskEnabled: !!ethereum,
        })
      } catch (e) {
        await new Promise((res) => setTimeout(res, 2000))
        await setWallet({
          signed: false,
          ethereum: null,
          initialized: true,
          isMetamaskEnabled: false,
        })
      }
    })()
  }, [])

  const init = useCallback(async () => {
    if (wallet.signed) {
      return
    }

    const selectedAddress = ethereum.selectedAddress
    const session = Cookies.get(`cgangsters_auth_${selectedAddress}`)

    let inMaintenance = false

    try{
      // const { data } = await axios.get(process.env.REACT_APP_SETTINGS_HOST || '')
      // inMaintenance = data.MAINTENANCE
    }catch(e){  }

    if (session) {
      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner()
      const contract = new ethers.Contract(
        process.env.REACT_APP_TOKEN_CONTRACT!,
        [
          "function balanceOf(address _owner) public view returns (uint256 balance)",
          "function transfer(address _to, uint256 _value) public returns (bool success)",
        ],
        signer
      )
      const contractBusd = new ethers.Contract(
        process.env.REACT_APP_TOKEN_BUSD_CONTRACT!,
        [
          "function balanceOf(address _owner) public view returns (uint256 balance)",
          "function transfer(address _to, uint256 _value) public returns (bool success)",
        ],
        signer
      )
      setWallet({
        ...wallet,
        signed: true,
        inMaintenance,
        account: selectedAddress,
        initialized: true,
        contract,
        contractBusd,
        provider,
      })
      return
    }

    setWallet({
      ...wallet,
      inMaintenance,
      initialized: true,
    })
  }, [ethereum, wallet])

  useEffect(() => {
    if (!ethereum) {
      return
    }

    setTimeout(init, 100)
  }, [ethereum, init])

  const login = useCallback(async () => {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" })
    const selectedAccount = accounts[0]

    const {
      data: {
        generateAuthChallenge: { nonce },
      },
    } = await generateChallenge({
      variables: {
        address: selectedAccount,
      },
    })

    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()
    const signature = await signer.signMessage(nonce)

    const {
      data: {
        signIn: { token },
      },
    } = await signIn({
      variables: {
        address: selectedAccount,
        signature,
      },
    })

    Cookies.set(`cgangsters_auth_${selectedAccount}`, token)

    setWallet({
      ...wallet,
      signed: true,
      account: selectedAccount,
    })
  }, [ethereum, wallet, generateChallenge, signIn])

  const logout = useCallback(() => {
    Object.keys(Cookies.get()).forEach((cookie) => {
      Cookies.remove(cookie)
    })

    setWallet({
      ...wallet,
      signed: false,
      account: undefined,
    })
    window.location.reload()
  }, [wallet])

  const onAccountChange = useCallback(async () => {
    logout()
  }, [logout])

  useEffect(() => {
    if (!ethereum) {
      return
    }
    ethereum.on("accountsChanged", onAccountChange)
    return () => ethereum.removeAllListeners()
  }, [ethereum, onAccountChange])

  useEffect(() => {
    if (!account) {
      return
    }
  }, [account, ethereum, onAccountChange])

  return !initialized ? (
    <LoadingBg />
  ) : (
    <WalletContext.Provider
      value={{
        settings: dataSettings?.settings as ISettings,
        signed,
        ethereum,
        isMetamaskEnabled,
        initialized,
        account,
        inMaintenance,
        contract,
        contractBusd,
        provider,
        login,
        logout,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider
