import React, { Suspense, memo } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import routes from "@routes"
import AppRoute from "./AppRoute"
import useGaTracker from "./gaTracker"
import { LoadingContainer } from "./style"
import { Grid } from "@mui/material"

const Main = () => {
  useGaTracker()
  return (
    <div className="fullHeight fullWidth flex-column">
      <Suspense
        fallback={
          <LoadingContainer
            container
            alignItems="center"
            justifyContent="center"
          />
        }
      >
        <Routes>
          {routes.map((route: any) => (
            <Route
              path={route.path}
              key={route.path}
              element={<AppRoute {...route} />}
            />
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
      {/* <ToastContainer closeButton={<CloseButton />} /> */}
    </div>
  )
}

export default memo(Main)
