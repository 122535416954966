import React from "react"

import "./App.css"
import Provider from "@Provider"
import { ApolloProvider } from "@apollo/client"
import { ThemeProvider } from "@mui/material/styles"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import { BrowserRouter as Router } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from 'react-toastify'
import Amplify, { PubSub } from "aws-amplify"
import { AWSIoTProvider } from "@aws-amplify/pubsub"
import {Helmet} from "react-helmet"
import client from "./resources/api/graphql"
import theme from "./Constants/theme"
import Main from "./Containers/Main"


Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:5952ef09-00d1-451b-9fef-e96657eae73e",
    region: "us-east-1",
  }
})

PubSub.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_IOT_REGION,
    aws_pubsub_endpoint: `wss://${process.env.REACT_APP_IOT_ENDPOINT}/mqtt`,
  })
)


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <Provider>
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>CryptoGangsters P2E</title>

								<meta property="og:site_name" content="CryptoGangsters" />
								<meta property="og:title" content="CryptoGangsters" />
								<meta property="og:description" content="P2E Game on BSC Network" />
								<meta property="og:type" content="website" />
								<meta property="og:url" content="https://cryptogangsters.me/" />

								{/* Facebook */}
								<meta property="og:image" content="https://cryptogangsters.me/static/media/mafia.185d2ce0.png" />
								<meta property="og:image:type" content="image/jpeg" />
								<meta property="og:image:width" content="400" />
								<meta property="og:image:height" content="300" />
								<meta property="og:image:alt" content="CryptoGangsters" />

								{/* Twitter */}
								<meta name="twitter:card" content="summary_large_image" />
								<meta name="twitter:site" content="@CGANGstersNFT" />
								
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="cache-control" content="no-cache, no-store, must-revalidate" />
                <meta name="theme-color" content="#000000" />
            </Helmet>
            <Router>
              <Main />
              <ToastContainer />
            </Router>
            </>
          </Provider>
        </ApolloProvider>
      </StyledThemeProvider>
    </ThemeProvider>
  )
}

export default App
