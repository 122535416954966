import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import Cookies from "js-cookie"
import { setContext } from "@apollo/client/link/context"
import detectEthereumProvider from "@metamask/detect-provider"
import { LIST_GANGSTERS } from "@graphql/Gangster/gangster.query"

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
})

const authMiddleware = setContext(async () => {
  const ethereum: any = await detectEthereumProvider()

  const headers = ethereum?.selectedAddress && {
    access_token: `${Cookies.get(
      `cgangsters_auth_${ethereum.selectedAddress}`
    )}`,
    authorization: `${Cookies.get(
      `cgangsters_auth_${ethereum.selectedAddress}`
    )}`,
  }

  return { headers }
})

const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache({
     typePolicies:{}
   
   
  }),
})

export default client
