import authRoutes from './auth'
import mainRoutes from './main'
import gameRoutes from './game'

const routes = [
  ...authRoutes,
  ...mainRoutes,
  ...gameRoutes
]

export default routes
