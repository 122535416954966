import React, { memo, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Navigate, Route } from "react-router-dom"
import Cookies from "js-cookie"
import { WalletContext } from "@Provider"

interface IDefaultLayout {
  children: React.ReactNode
}

const DefaultLayout = ({ children }: IDefaultLayout) => <>{children}</>

interface IAppRoute {
  component: any
  layout: any
  open: boolean
}

const AppRoute = ({
  component: Component,
  layout: Layout,
  open,
  ...otherProps
}: IAppRoute) => {
  const {  isMetamaskEnabled, initialized, account, login, logout } = useContext(WalletContext)

  const [auth, setAuth] = useState({ isAuthenticated: false, loaded: false })

  // useEffect(() => {
  //   if (isNotificationSupported() && !isNotificationAllowed()) {
  //     getUserNotificationPermission()
  //   }
  // }, [])

  useEffect(() => {

    if(!initialized){ return}

    if (open) {
      return setAuth({ isAuthenticated: true, loaded: true })
    }

    const cookie = Cookies.get(`cgangsters_auth_${account}`)
    if (isMetamaskEnabled && cookie) {
      return setAuth({ isAuthenticated: true, loaded: true })
    }

    return setAuth({ isAuthenticated: false, loaded: true })
  }, [open,initialized,account, isMetamaskEnabled])

  const renderLayout = () => (
    <Layout {...otherProps} >
      <Component {...otherProps}  />
    </Layout>
  )

  const isAuthenticated = () =>
    auth.isAuthenticated ? renderLayout() : <Navigate to="/" />

  return auth.loaded ? isAuthenticated() : null

}

AppRoute.defaultProps = {
  layout: DefaultLayout,
  open: false,
}
AppRoute.propTypes = {
  component: PropTypes.object.isRequired,
  layout: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]),
  open: PropTypes.bool,
}

export default memo(AppRoute)
