import { createTheme, responsiveFontSizes } from "@mui/material/styles"
import { red } from "@mui/material/colors"

// A custom theme for this app
let theme = createTheme({
  typography: {
    fontFamily: ['"Montserrat"', "Open Sans"].join(","),
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#DB7724",
    },
    secondary: {
      main: "#334457",
    },
    error: {
      main: red.A400,
    },
  },
})

theme.typography = {
  ...theme.typography,
  h2: {
    ...theme.typography.h2,
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    }
  },
  h4: {
    ...theme.typography.h4,
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    }
  },
  h6: {
    ...theme.typography.h6,
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    }
  }
}

theme = responsiveFontSizes(theme);

export default theme
