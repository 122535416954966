import { gql } from '@apollo/client'

export const GENERATE_AUTH_CHALLENGE = gql`
  mutation GENERATE_AUTH_CHALLENGE(
    $address: String!
  ) {
    generateAuthChallenge(address: $address) {
      nonce
    }
  }
`


export const SIGN_IN = gql`
  mutation SIGN_IN(
    $address: String!
    $signature: String!
  ) {
    signIn(data: {
      address: $address,
      signature: $signature,
    }) {
      token
    }
  }
`
