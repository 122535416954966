import { lazyWithRetry } from './lazyReload'

const Layout = lazyWithRetry(() => import('../Containers/Game/Layout'))

const AccountContainer = lazyWithRetry(() => import('../Containers/Game/Account'))
const HomeGame = lazyWithRetry(() => import('../Containers/Game/Home'))
const GangsterContainer = lazyWithRetry(() => import('../Containers/Game/Gangster'))
const GangTownContainer = lazyWithRetry(() => import('../Containers/Game/GangTown'))
const MarketplaceContainer = lazyWithRetry(() => import('../Containers/Game/Marketplace'))
// const StakingContainer = lazyWithRetry(() => import('../Containers/Game/Staking'))
const PvPContainer = lazyWithRetry(() => import('../Containers/Game/PvP'))
const MinigamesContainer = lazyWithRetry(() => import('../Containers/Game/Cassino'))
const ShooterContainer = lazyWithRetry(() => import('../Containers/Game/Cassino/Shooter'))
const HilowContainer = lazyWithRetry(() => import('../Containers/Game/Cassino/Hilow'))
const MinesContainer = lazyWithRetry(() => import('../Containers/Game/Cassino/Mines'))
const AlleyContainer = lazyWithRetry(() => import('../Containers/Game/Alley'))
const TreasureHuntContainer = lazyWithRetry(() => import('../Containers/Game/Cassino/TreasureHunt'))
const WheelsContainer = lazyWithRetry(() => import('../Containers/Game/Cassino/Wheels'))


const game = [
  {
    path: '/play',
    component: HomeGame,
    layout: Layout,
    exact: true,
    open: true
  },
  {
    path: '/play/marketplace',
    component: MarketplaceContainer,
    layout: Layout,
    exact: true,
    open: true
  },
  // {
  //   path: '/play/staking',
  //   component: StakingContainer,
  //   layout: Layout,
  //   exact: true,
  //   open: true
  // },
  {
    path: '/play/gangsters',
    component: GangsterContainer,
    layout: Layout,
    exact: true,
    open: false
  },
  {
    path: '/play/gangtown',
    component: GangTownContainer,
    layout: Layout,
    exact: true,
    open: false
  },
  {
    path: '/play/alley',
    component: AlleyContainer,
    layout: Layout,
    exact: true,
    open: false
  },
  {
    path: '/play/account',
    component: AccountContainer,
    layout: Layout,
    exact: true,
    open: false
  },
  {
    path: '/play/pvp',
    component: PvPContainer,
    layout: Layout,
    exact: true,
    open: false
  },
  {
    path: '/play/minigames',
    component: MinigamesContainer,
    layout: Layout,
    exact: true,
    open: false
  },
	{
    path: '/play/minigames/viktor-escape',
    component: ShooterContainer,
    layout: Layout,
    open: false
  },
  {
    path: '/play/minigames/hi-lo-tarrara',
    component: HilowContainer,
    layout: Layout,
    open: false
  },
  {
    path: '/play/minigames/antonio-mines',
    component: MinesContainer,
    layout: Layout,
    open: false
  },
  {
    path: '/play/minigames/treasure-hunt',
    component: TreasureHuntContainer,
    layout: Layout,
    open: false
  },{
    path: '/play/minigames/yoko-wheels',
    component: WheelsContainer,
    layout: Layout,
    open: false
  },
]

export default game
