import { lazyWithRetry } from './lazyReload'

// const Layout = lazy(() => import('../Containers/Auth/Layout'))

const LoginContainer = lazyWithRetry(() => import('../Containers/Auth'))

const auth = [
  {
    path: '/auth',
    component: LoginContainer,
    // layout: Layout,
    exact: true,
    open: true
  }
]

export default auth
