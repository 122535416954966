import { lazyWithRetry } from './lazyReload'

// const Layout = lazy(() => import('../Containers/Auth/Layout'))

const LandingContainer = lazyWithRetry(() => import('../Containers/Landing'))

const main = [
  {
    path: '/',
    component: LandingContainer,
    // layout: Layout,
    exact: true,
    open: true
  }
]

export default main
