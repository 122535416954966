import { gql } from '@apollo/client'

export const SETTINGS = gql`
  query SETTINGS {
    settings{
        BOX_PRICE
        BOX_ITEM_PRICE
        BAIL_OUT_PRICE
        RENAME_PRICE
        QUICK_PRICE
        MAX_SLOT
        MIN_SLOT
        SLOT_PRICE
        SLOT_STEP_PRICE
       
        LEVEL_UP_POINTS_AMOUNT
        UPGRADE_PRICE
        DUEL_CHALLENGER_MAX_PERIOD
        DUEL_CHALLENGED_MAX_PERIOD
        CHANGE_PHOTO_AND_NAME_PRICE
        DUEL_SPECIFIC_PRICE
        DUEL_RANDOM_PRICE
        DUEL_QUICK_PRICE
        MIN_WITHDRAW
        MIN_WITHDRAW_BUSD
        MAX_WITHDRAW
        HILOW_MAX_PRICE
        HILOW_MIN_PRICE
        MINES_MAX_PRICE
        MINES_MIN_PRICE
        SHOOTER_MIN_PRICE
        SHOOTER_MAX_PRICE
        HILOW_BUSD_MAX_PRICE
        HILOW_BUSD_MIN_PRICE
        MINES_BUSD_MAX_PRICE
        MINES_BUSD_MIN_PRICE
        SHOOTER_BUSD_MIN_PRICE
        SHOOTER_BUSD_MAX_PRICE
        DEPOSIT
        DEPOSIT_MSG
        DEPOSIT_BUSD
        DEPOSIT_BUSD_MSG
        WHEELS_MIN_PRICE
        WHEELS_MAX_PRICE
        WHEELS
        WHEELS_BUSD_MIN_PRICE
        WHEELS_BUSD_MAX_PRICE

        CAN_SELL_GANGSTER
        SELL_GANGSTER_PRICE
    }
  }
`