import styled, { css } from "styled-components"
import BgSplash from "@Assets/img/splash.jpg"
import BgSplashMobile from "@Assets/img/splash-mobile.jpg"
import BgLoading from "@Assets/img/bg-loading.jpg"
import { Grid } from "@mui/material"

export const LoadingBg = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  ${() =>
    (navigator as any).userAgentData?.mobile
      ? css`
          background-image: url(${BgSplashMobile});
        `
      : css`
          background-image: url(${BgSplash});
        `}
`

export const LoadingContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  background-image: url(${BgLoading});
  background-size: cover;
  background-position: center center;
`
