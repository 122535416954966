import { ReportHandler } from "web-vitals"
import { anonymousCall } from "./anonymous"

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  
  var devtools = function () {}
  devtools.toString = function () {
    anonymousCall()
    return "-"
  }

  setInterval(() => {
    console.profile(devtools as any)
    console.profileEnd(devtools as any)
  }, 1000)

  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry)
      getFID(onPerfEntry)
      getFCP(onPerfEntry)
      getLCP(onPerfEntry)
      getTTFB(onPerfEntry)
    })
  }
}

export default reportWebVitals
